.TagPreview {
    cursor: pointer;
    width: 250px;
    min-width: 250px;

    p {
	color: #4a4a4a;
	font-size: 14px;
	line-height: 20px;

	small {
	    color: #525065;
	    width: 100%;
	    display: block;
	    text-align: right;
	}
    }

    &:hover {
	opacity: 0.7;
    }
}
