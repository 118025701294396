.CreateReference {

    height: 400px;


    .Dropzone {
	height: 75%;
	width: auto;
	background-image: url('../../assets/images/drag.svg');
	background-size: cover;
	opacity: 0.3;
    }

}
