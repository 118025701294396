.InfoBar {
    position: fixed;
    top: calc(64px +25px);
    right: 3%;
    max-width: 10%;

    .InfoRow {
	margin-top: 15px;
    }

    .Saving {
	align-self: flex-end;
	color: #4a4a4a;
	font-size: 11px;
    }
}
