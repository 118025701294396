.VideoPlayer {
    position: fixed;
    background-color: #000;
    height: 100vh;
}

.VideoComments {
    background-color: #fff;

    .Comment {
	color: red;
	font-size: 28px;
    }
}
