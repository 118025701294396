.GistsHero {
    background-color: #f4f4f4;
    margin-bottom: 50px;

    h1 {
	margin-block-start: 0;
	margin-block-end: 0;
	color: #535166;
	font-size: 2.8rem;
	line-height: 1;
	font-weight: 400;
    }

    p {
	color: #4a4a4a;
	font-size: 1.2rem;
	line-height: 1.2;
    }
}
