

.Toolbar {
    position: fixed;
    top: 69px;
    left: 0;
    right: 0;
    background-color: #fff;
    height: 48px;
    min-height: 48px;
    z-index: 100;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
    padding: 0 36px;
}
