.ExplorationsList {
    padding-top: 100px;
}

.EmptyState {
    font-size: 26px;
    color: #4a4a4a;
}

.PaginationContainer {
    margin: 25px 0;
}
