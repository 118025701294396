.Copied {
    color: #514F63;
    margin-right: 10px;
}


.ToggleRead {
    align-self: flex-end;
}

.ReadIcon {
    font-size: 28px !important;
    color: #ccc;
}

.created {
    color: gray;
}

.progress {
    color: blue;
}

.resolved {
    color: #50bb62;
}

.ReferencesTable {
    font-size: 14px;
    color: #4a4a4a;

    div {
	padding: 5px;

	a {
	    color: #4a4a4a;
	    text-decoration: none;

	    &:hover {
		opacity: 0.8;
	    }
	}
    }

    div:hover button {
	display: block;
    }

    button {
	display: none;
    }


}

.CommentsBoard {
    background-color: #eee;
    height: 900px;

    .CommentsArea {
	height: 100%;
    }
}
