.ReferenceDisplayWrapper {
    margin-top: 35px;

    .ReferenceDisplay {

	img {
	    max-width: 100%;
	    height: auto;
	}
    }
}
