.FiltersBar {
    background-color: transparent;
}

.Filters {
    height: 25px;
    transition: all .5s ease-in-out;
    visibility: hidden;
    border-bottom: 1px solid #eee;
}

.Show {
    visibility: visible;
    height: 75px;
}
