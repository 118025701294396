#ReferenceEditor {
    position: relative;
    margin-top: 50px;
    outline: none;

    img {
	position: absolute;
	left: 0;
	top: 0;
	outline: none;
    }

}

.Status {
    color: #4a4a4a;
    margin-top: 25px;
    font-size: 13px;

    p {
	margin-block-start: 0;
	margin-block-end: 5px;
    }
}


#Comment {
    background-color: yellow;
    color: #4a4a4a;
    position: absolute;
    width: 300px;
    border: 0;
    padding: 10px 15px 0 15px;

    &:hover {
	cursor: move;
	background-color: lighten(yellow, 20);
    }

    .CommentHeader {
	margin-bottom: 10px;
	padding-top: 0;
	margin-left: -3px;

	.Author {
	    margin-left: 5px;
	    font-size: 13px;
	    font-weight: bold;
	}

	.Datetime {
	    font-size: 12px;
	    font-weight: 400;
	}

	.CollapsedDescription {
	    font-size: 16px;
	    font-weight: 400;
	    padding-bottom: 5px;
	}

    }
}
