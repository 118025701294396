@import "../../mixins.scss";

.ExplorationPreview{
    position: relative;
    min-width: 300px;

    a {
	text-decoration: none;
	color:  #4a4a4a;
	opacity: 1;
    }

    .Overlay {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: #4a4a4a;
	opacity: 0;
    }

    &:hover .Overlay {
	opacity: 0.75;
    }

    .Tags {
	padding: 10px;
	color: #fff;
	font-weight: 500;
    }

    .Name {
	@include TruncateText;
    }

    .ReadIcon {
	position: absolute;
	right: 15px;
	top: 15px;
	font-size: 28px !important;
	color: #ccc;
    }

    .created {
	color: gray;
    }

    .progress {
	color: blue;
    }

    .resolved {
	color: #50bb62;
    }


}

.Selected {
    border: 5px solid #D2CFE1;
}


.ExplorationImage {
    height: 200px;
}

.Icon {
    color: #6a6a6a;
    margin-left: 10px;
}

.IconActive {
    color: #76799C;
}

.CheckIcon {
    color: white;
    margin: 10px;
    opacity: 1;
}

.CheckIconActive {
    color: #D2CFE1;
    opacity: 1;
}
