.LinkTitle {
    h3 {
	font-size: 20px;
	font-weight: 300;
	line-height: 1.334;
	color: rgba(0, 0, 0, 0.87);
	padding: 10px;
	margin-bottom: 0;
    }

    h3:hover {
	box-shadow: 0 0 0 3px rgba(66,153,225,.5);
	border-radius: .25rem;
    }

    p {
	font-size: 13px;
	margin-block-start: 0;
	padding: 10px;
	margin-top: -5px;
    }

}


.Copied {
    color: #514F63;
    margin-right: 10px;
}


.ToggleRead {
    align-self: flex-end;
}

.ReadIcon {
    font-size: 28px !important;
    color: #ccc;
}

.notRead {
    // gray
    color: gray;
}

.readLater {
    // yellow
    color: yellow;
}

.reading {
    // blue
    color: blue;
}

.read {
    color: #50bb62;
}

.CommentsBoard {
    background-color: #eee;
    height: 900px;

    .CommentsArea {
	height: 100%;
    }
}
