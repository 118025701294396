.GistsHero {
    background-color: #f4f4f4;
    margin-bottom: 50px;

    h1 {
	margin-block-start: 0;
	margin-block-end: 0;
	color: #535166;
	font-size: 2.8rem;
	line-height: 1;
	font-weight: 400;
    }

    p {
	color: #4a4a4a;
	font-size: 1.2rem;
	line-height: 1.2;
    }
}

.GistsList {
    padding-top: 50px;
}

.CreateGistFab {
    position: fixed;
    bottom: 500px;
    left: 500px;
}

.EmptyState {
    font-size: 26px;
    color: #4a4a4a;
}
