.GistPreview {
    cursor: pointer;

    p {
	color: #4a4a4a;
	font-size: 14px;
	line-height: 20px;
    }

    &:hover {
	opacity: 0.7;
    }
}
