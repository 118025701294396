@import "../../mixins.scss";

.LinkTitle {
    h3 {
	font-size: 20px;
	font-weight: 300;
	line-height: 1.334;
	color: rgba(0, 0, 0, 0.87);
	padding: 10px;
	margin-bottom: 0;
    }

    h3:hover {
	box-shadow: 0 0 0 3px rgba(66,153,225,.5);
	border-radius: .25rem;
    }

    p {
	font-size: 13px;
	margin-block-start: 0;
	padding: 10px;
	margin-top: -5px;
    }

}

.LinkPreview {
    cursor: pointer;

    h3 {
	@include TruncateText
    }

    p {
	color: #4a4a4a;
	font-size: 14px;
	line-height: 20px;
	@include TruncateText
    }

    &:hover {
	opacity: 0.7;
    }
}

.LinkPreviewDetails {

    a {
	color: #4a4a4a;
	text-decoration: none;

	&:hover {
	    opacity: 0.7;
	}

    }

    .ToggleRead {
	align-self: flex-end;
    }

    .ReadIcon {
	font-size: 28px !important;
	color: #ccc;
    }

    .notRead {
	// gray
	color: gray;
    }

    .readLater {
	// yellow
	color: yellow;
    }

    .reading {
	// blue
	color: blue;
    }

    .read {
	color: #50bb62;
    }

}

.CommentsBoard {
    background-color: #eee;
    height: 900px;

    .CommentsArea {
	height: 100%;
    }
}
